<template>
  <section class="build-in-2020">
    <section class="hero">
      <div class="hero-container">
        <h1>Quelles aides pour faire construire sa maison en 2020 ?</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/build-in-2020-1.jpg"
        alt="Quelles aides pour faire construire sa maison en 2020 ?"
      />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Prêts bonifiés, exonérations d’impôts, subventions locales… de nombreux leviers financiers
          existent pour soutenir les primo-accédants dans la réalisation de leur projet immobilier.
        </h3>
      </div>
      <div class="article-card fit-content">
        <div class="article-card-container">
          <p>
            L’Etat, les entreprises, les banques et les collectivités locales proposent, chacun à
            leurs niveaux, des solutions pour accompagner un ménage qui
            <router-link to="/projet-construction-maison/ou-faire-construire-sa-maison"
              >souhaite faire construire sa maison</router-link
            >. Objectifs : diminuer les mensualités de remboursement de la demande, et accroître la
            capacité d’emprunt des candidats à la propriété, pour encourager l’offre immobilière,
            notamment en zones urbaines (agglomérations), là où le marché est le plus tendu. Les
            principaux dispositifs en vigueur cette année seront maintenus en 2020 avec, pour
            certains d’entre eux, quelques ajustements liés à la localisation du bien neuf.
          </p>
          <p>
            C’est le cas du prêt à taux zéro, première aide en volume (87 000 souscription en 2018)
            accordée aux primo-accédants. Comme son nom l’indique, ce financement, subventionné par
            l’Etat, permet d’emprunter sans intérêt jusqu’à 40% du prix du bien à bâtir. Cette
            quotité maximale s’applique dans les grandes agglomérations de plus de 250 000
            habitants, contre 20% dans les villes moyennes et en milieu rural (jusqu’à la fin 2019,
            avant une possible suppression de cet avantage dans ces deux types de territoire l’an
            prochain). A noter que le critère géographique ne conditionne pas à lui seul
            l’attribution du PTZ : le niveau de ressources des demandeurs, calculé sur la base de
            leur revenu fiscal de référence, rapporté au nombre de personnes destinées à occuper le
            futur logement, entre également en ligne de compte dans la définition des plafonds
            d’éligibilité. Autre restriction : Seuls les primo-accédant sont en droit de prétendre
            au dispositif, sous réserve qu’ils contractent, parallèlement, un crédit classique, le
            PTZ ne venant qu’en complément de ce dernier.
          </p>
        </div>
        <div class="article-card-img">
          <img
            src="@/assets/img/pages/build-in-2020-2.jpg"
            alt="Prêts bonifiés, exonérations d’impôts, subventions locales…"
          />
        </div>
      </div>
      <div class="article-loan fit-content">
        <h2>Le prêt « action logement »</h2>
        <div class="article-loan-text">
          <p>
            Il cible les salariés des entreprises qui versent à l’Etat une cotisation patronale au
            titre de leur participation à« l’effort de construction » (Peec). Cet argent est
            redistribué sous la forme d’un prêt bonifié (au taux préférentiel de 1%) compris entre 7
            000 et 25 000 euros en fonction du lieu de construction du bien (commune rurale, ville
            moyenne, grande agglo). Pour solliciter cette aide, il convient de s’adresser à son
            employeur ou déposer une demande sur www.actionlogement.fr.
          </p>
        </div>
        <card-type-img title="Pas de taxe foncière pendant deux ans">
          <template v-slot:content>
            <p>
              Les acquéreurs d’une propriété bâtie à usage d’habitation (résidence principale ou
              secondaire) sont automatiquement exonérés de cet impôt local, sauf avis contraire de
              la commune qui en reçoit une part. Cet avantage, valable deux ans, doit faire l’objet
              d’une demande adressée au Centre des Finances Publiques dans les 90 jours qui suivent
              l’achèvement du bien.
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/build-in-2020-3.jpg"
              alt="Pas de taxe foncière pendant deux ans"
            />
          </template>
        </card-type-img>
        <h2>Des subventions locales</h2>
        <div class="article-loan-img">
          <img src="@/assets/img/pages/build-in-2020-4.jpg" alt="Des subventions locales" />
        </div>
        <p>
          Dans un souci d’attractivité, certaines collectivités publiques (conseils départementaux,
          municipalités, communautés de communes) délivrent des aides aux particuliers qui
          souhaitent faire construire sur les territoires qu’elles administrent. Ces coups de pouces
          prennent la forme de prêts bonifiés, voire de primes attribuées sous condition de
          ressources. Les modalités ces dispositifs d’accompagnement diffèrent en fonction des
          enjeux locaux définis dans le cadre des politiques publiques. Au sein de l’agglomération
          nantaise par exemple, des programmes en accession abordable prévoient la vente de
          logements neufs à des prix inférieurs au marché (de -15 à -20%) : le public ciblé est
          modeste (revenus conformes aux plafonds retenus pour obtenir un prêt locatif social) et
          les candidats éligibles doivent s’engager à résider dans cette résidence principale
          pendant 7 ans minimum. A l’échelon départemental, des « passeports accession » peuvent
          être proposés (se renseigner auprès de l’Adil, Agence départemental d’information sur le
          Logement).
        </p>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'build-in-2020',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.build-in-2020
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      &-container
        background-color: $white
        border-radius: $global-border-radius
        box-shadow: $drop-shadow
        padding: 2rem
        a
          text-decoration: none
          font-size: inherit
          color: $primary
      &-img
        margin-top: 4.5rem
        img
          width: 100%
          border-radius: $global-border-radius
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-loan
      margin: 4.5rem auto 0 auto
      > h2
        margin-bottom: 2.5rem
        text-align: center
      h2
        color: $primary
      &-img
        margin-bottom: 2.5rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      .card-type-img
        margin-bottom: 4.5rem
      &-text
        margin-bottom: 4.5rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%;
    max-width: 950px;
  @media (max-width: 768px)
    .hero
      height: 20rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          margin-top: -5rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
        &-container
          padding: 2rem 1rem
      &-loan
        margin: 3rem auto 0 auto
        h2
          margin-bottom: 0
        &-img
          margin-top: 1.5rem
        &-text
          margin-top: 1.5rem
</style>
